import { useCallback, useEffect, useMemo } from "react";

import { useMutation } from "@tanstack/react-query";
import { mercurePush } from "api/mercure";
import useCallbackRef from "hooks/callback_ref/useCallbackRef";
import { MERCURE_HUB_URL } from "util/const";
import { getTokens } from "util/token";

type Return<T> = {
  push: (data: T) => void;
  eventSource?: EventSource;
  error?: Error;
};
type Options = {
  publishToken?: string;
  disable?: boolean;
  lastMessageId?: string;
  source?: string;
  onError?: (error: Error) => void;
  onOpen?: () => void;
};

const getCookie = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    return parts.pop()?.split(";").shift();
  }
};

const isStringArray = (value: (string | undefined)[]): value is string[] =>
  value.every(v => typeof v === "string");

const DEFAULT_SOURCE = "master";

const pending: Record<string, Promise<void>> = {};
const sources: Record<string, EventSource | null> = {};
const handlers: Record<string, ((data: { data: string }) => void)[]> = {};

const useMercureTopic = <T, R = T>(
  topic:
    | string
    | string[]
    | undefined
    | Promise<string | undefined>
    | Promise<string | undefined>[],
  onData: (data: R) => void,
  options: Options = {},
): Return<T> => {
  const { 2: mercureToken } = getTokens();
  const onDataRef = useCallbackRef(onData);

  const onOpenRef = useCallbackRef(() => {
    options.onOpen?.();
  });

  const token = useMemo(
    () => getCookie("mercureAuthorization") ?? options.publishToken ?? mercureToken?.token,
    [mercureToken, options.publishToken],
  );

  useEffect(() => {
    if (options.disable || !topic || (Array.isArray(topic) && topic.length === 0)) {
      return;
    }

    // let isConnecting = false;
    const sourceStr = options.source ?? DEFAULT_SOURCE;
    const url = new URL(MERCURE_HUB_URL);
    let ignore = false;

    const delayed = async () => {
      // isConnecting = true;
      if (ignore) {
        return;
      }

      let resolvedTopic: (string | undefined)[];

      if (topic instanceof Promise) {
        resolvedTopic = [await topic].filter(t => !!t);
      } else if (Array.isArray(topic) && topic.some(t => t instanceof Promise)) {
        resolvedTopic = (await Promise.all(topic)).filter(t => !!t);
      } else {
        resolvedTopic = (Array.isArray(topic) ? (topic as string[]) : [topic]).filter(t => !!t);
      }

      if (!isStringArray(resolvedTopic) || resolvedTopic.length === 0) {
        return;
      }

      if (Array.isArray(resolvedTopic)) {
        for (const t of resolvedTopic) {
          url.searchParams.append("topic", t);
        }
      } else {
        url.searchParams.append("topic", resolvedTopic);
      }

      if (options.lastMessageId) {
        url.searchParams.append("lastEventID", options.lastMessageId);
      }

      console.info("connecting to mercure", url.toString());

      while (sources[sourceStr] === null) {
        console.info("useMercureTopic", "waiting for connection to be established", sourceStr);
        const p = pending[sourceStr];
        await p;
      }

      let evSource = sources[sourceStr];

      if (evSource === undefined) {
        let resolve = () => {};

        // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
        pending[sourceStr] = new Promise(r => (resolve = r));
        sources[sourceStr] = null; // mark as connecting, so we don't try to connect again

        if (process.env.NODE_ENV === "development") {
          // SSSP 4 says it suports EventSource but its not.
          // this polyfill is an absolute disaster but there is no other way
          const polyfill = await import("event-source-polyfill");
          const { EventSourcePolyfill } = polyfill;

          evSource = new EventSourcePolyfill(url.toString(), {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        } else {
          evSource = new EventSource(url.toString(), {
            withCredentials: true,
          });
        }

        evSource.onopen = () => {
          console.debug("useMercureTopic", "connected to mercure hub", sourceStr);
          onOpenRef();
        };

        evSource.onmessage = ({ data }: { data: string }) => {
          for (const handler of handlers[sourceStr]) {
            handler(JSON.parse(data));
          }
        };

        handlers[sourceStr] = [];
        sources[sourceStr] = evSource;
        resolve?.();
      } else {
        console.debug("useMercureTopic", "connected to mercure hub (reused)", sourceStr);
        onOpenRef();
      }

      const onMessage = ({ data }: { data: string }) => onDataRef(JSON.parse(data));

      handlers[sourceStr].push(onMessage);

      // isConnecting = false;
    };

    delayed();

    // let timer: number;
    // let wasHidden = document.hidden;

    // const onVisibilityChange = () => {
    //   if (wasHidden && document.visibilityState === "visible") {
    //     if (!isConnecting) {
    //       console.info(
    //         "useMercureTopic",
    //         "tab is active reconnecting to mercure",
    //         sourceStr
    //       );

    //       delayed();
    //     }
    //   } else if (!wasHidden && document.visibilityState === "hidden") {
    //     console.info(
    //       "useMercureTopic",
    //       "tab is inactive disconnecting from mercure",
    //       sourceStr
    //     );
    //     clearTimeout(timer);
    //     timer = window.setTimeout(() => {
    //       const evSource = sources[sourceStr];
    //       if (evSource) {
    //         evSource.close();
    //         delete sources[sourceStr];
    //       }
    //     }, 2000);
    //   }

    //   wasHidden = document.visibilityState === "hidden";
    // };

    // document.addEventListener("visibilitychange", onVisibilityChange);

    return () => {
      // document.removeEventListener("visibilitychange", onVisibilityChange);
      // clearTimeout(timer);
      ignore = true;

      const evSource = sources[sourceStr];

      if (!evSource) {
        return;
      }

      handlers[sourceStr] = handlers[sourceStr].filter(handler => handler !== onDataRef);

      // if no more listeners, close the connection
      if (handlers[sourceStr].length === 1) {
        evSource.close();
        delete sources[sourceStr];
      }
    };
  }, [token, topic, onDataRef, options.source, options.lastMessageId, onOpenRef, options.disable]);

  const mutation = useMutation({
    mutationFn: mercurePush,
    onSuccess: onDataRef,
    // onError: options.onError ?? ((error: Error) => notifyError(notify, t`labels.could_not_push|Could not push`, error))
    onError: (error: Error) => {
      console.warn("mercure push error", error);
      options.onError?.(error);
    },
  });

  const push = useCallback(
    async (data: T) => {
      if (!token) {
        throw new Error("no token present");
      }

      if (!topic) {
        throw new Error("no topic present");
      }

      const body = new URLSearchParams();

      let resolvedTopic: (string | undefined)[];

      if (topic instanceof Promise) {
        resolvedTopic = [await topic].filter(t => !!t);
      } else if (Array.isArray(topic) && topic.some(t => t instanceof Promise)) {
        resolvedTopic = (await Promise.all(topic)).filter(t => !!t);
      } else {
        resolvedTopic = (Array.isArray(topic) ? (topic as string[]) : [topic]).filter(t => !!t);
      }

      if (!isStringArray(resolvedTopic) || resolvedTopic.length === 0) {
        return;
      }

      if (Array.isArray(resolvedTopic)) {
        for (const t of resolvedTopic) {
          body.append("topic", t);
        }
      } else {
        body.append("topic", resolvedTopic);
      }

      body.append(
        "data",
        JSON.stringify({
          ...data,
          origin: "front",
        }),
      );

      mutation.mutate({ body, token });
    },
    [mutation, token, topic],
  );

  return useMemo(
    () => ({
      push,
      eventSource: sources[options.source ?? DEFAULT_SOURCE] ?? undefined,
      error: mutation.error || undefined,
    }),
    [mutation.error, push, options.source],
  );
};

export default useMercureTopic;
